import React from "react";
import "./Cta.css";
import conecta from "../../assets/imgs/conosco.svg";
function Cta() {
  return (
      <div className="cta-container">
        <div className="cta-content">
          <img src={conecta} alt="" />
          <p>
            Caro Cliente, você sabia que a advocacia é como um jogo de xadrez,
            onde cada movimento é estrategicamente planejado para antecipar as
            ações do adversário e alcançar um resultado favorável? No tabuleiro
            jurídico, atuamos como mestres estrategistas, analisando
            cuidadosamente cada peça e suas possíveis movimentações. Prever
            várias jogadas à frente e adaptar a estratégia conforme o desenrolar
            do jogo é crucial.
          </p>
          <p>
          Cada caso jurídico é uma partida única, com regras, peças e desafios próprios. Utilizamos táticas variadas, desde ataques diretos até manobras sutis, sempre para proteger os interesses de nossos clientes e avançar suas posições no "tabuleiro" judicial. Assim como os enxadristas, estamos sempre atualizando nossos conhecimentos e práticas.
          </p>
          <p>
          Na Fernandes & Freitas Advocacia e Consultoria, vemos a advocacia como um jogo de xadrez. Cada caso é uma nova partida, cada cliente uma nova oportunidade para demonstrar nossa perícia estratégica. Se você busca um escritório de advocacia que combine conhecimento jurídico profundo com uma abordagem estratégica e precisa, entre em contato conosco. Estamos prontos para ajudá-lo a vencer seu jogo jurídico.

          </p>
        </div>
      </div>
  );
}

export default Cta;
