import React, { useState } from "react";
import "./Faq.css";
import faq from "../../assets/imgs/faq.svg";

function FAQ() {
  const faqData = [
    {
      question: "Qual é a importância do planejamento previdenciário para o futuro financeiro?",
      answer:
        "O planejamento previdenciário é crucial para garantir uma aposentadoria estável e confortável. Ele envolve estratégias para maximizar os benefícios da previdência social e privada, considerando suas circunstâncias individuais e objetivos financeiros a longo prazo.",
    },
    {
      question: "Quais são as principais formas de redução da carga tributária em empresas?",
      answer:
        "Existem diversas estratégias legais para reduzir a carga tributária em empresas, como a escolha do regime tributário mais adequado, utilização de incentivos fiscais, planejamento tributário para otimização de impostos, entre outras. É essencial buscar orientação especializada para implementar essas medidas de forma eficaz e dentro da legislação.",
    },
    {
      question: "Quais são os direitos dos trabalhadores em relação à jornada de trabalho e remuneração?",
      answer:
        "Os trabalhadores têm direitos assegurados por lei em relação à jornada de trabalho, remuneração, horas extras, férias, décimo terceiro salário, entre outros. É fundamental conhecer e defender esses direitos para garantir condições de trabalho justas e dignas.",
    },
    {
      question: "Como a mediação de conflitos pode beneficiar empresas em disputas comerciais?",
      answer:
        "A mediação de conflitos é uma alternativa eficaz para resolver disputas comerciais de forma mais rápida, econômica e amigável do que processos judiciais tradicionais. Ela permite que as partes envolvidas cheguem a um acordo mutuamente satisfatório, preservando relacionamentos comerciais e evitando os custos e incertezas de litígios prolongados.",
    },
    {
      question: "Quais são as principais responsabilidades legais dos empresários no Brasil?",
      answer:
        "Os empresários têm várias responsabilidades legais, incluindo o cumprimento das leis trabalhistas, tributárias e empresariais, manutenção de registros contábeis e fiscais precisos, garantia da segurança no local de trabalho e proteção dos direitos dos consumidores. É essencial estar ciente dessas responsabilidades e agir de acordo para evitar problemas legais.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-wrapper">
      <div className="faq-container">
        <img className="faq-img" src={faq} alt="faq" />
        <div className="faq-list">
          {faqData.map((item, index) => (
            <div key={index} className="faq-item">
              <div
                className={`faq-question ${
                  activeIndex === index ? "active" : ""
                }`}
                onClick={() => handleToggle(index)}
              >
                <div className="question-text">{item.question}</div>
                <div className="toggle-icon">
                  {activeIndex === index ? "-" : "+"}
                </div>
              </div>
              <div
                className={`faq-answer ${
                  activeIndex === index ? "show" : "hide"
                }`}
              >
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
