import React from "react";
import "./Especialidades.css";
import Logo from '../../assets/imgs/fff.svg';
import especialidadesData from './especialidades.json';
import conheca from '../../assets/imgs/especialidades.svg';
import logo2 from "../../assets/imgs/ff.svg";
import peao from "../../assets/imgs/peao.svg";


function Especialidades() {
  const whatsappMessage = "Olá, gostaria de saber mais sobre a consultoria advocatícia.";
  const whatsappLink = `https://wa.me/5584991343413?text=${encodeURIComponent(whatsappMessage)}`;
  return (
    <div class="especialidades-wrapper">
      <div className="especialidades-container">
        <div className="header">
          <img className="img-menor" src={logo2} alt="logo"></img>
          <img className="img-maior" src={conheca}alt="" />
        </div>
        <div className="servicos-grid">
          {especialidadesData.map((item, index) => (
            <div key={index}>
              <div>
                <img className="peao-img" src={peao} alt="" />
                <h3>{item.titulo}</h3>
              </div>
              <p>{item.texto}</p>
              <a
                href={whatsappLink}
                target="_blank"
                rel="noreferrer"
              >
                <i className="uil uil-angle-double-right"></i> Saiba mais
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Especialidades;
