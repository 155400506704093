import React from "react";
import "./Artigos.css";
import Logo from '../../assets/imgs/fff.svg';
import acoes from "../../assets/imgs/acoes.svg";
import artigosData from './artigos.json';
import peao from "../../assets/imgs/peao.svg";

function Artigos() {
  return (
    <div class="artigos-wrapper">
      <div className="artigos-container">
        <div className="header2">
        <img className="img-maior" src={acoes} alt="logo"></img>
          <img className="img2-menor" src={Logo} alt="logo"></img>
  
        </div>
        <div className="servicos2-grid">
          {artigosData.map((item, index) => (
            <div key={index}>
              <div>
              <img className="peao-img" src={peao} alt="" />
                <h3>{item.titulo}</h3>
              </div>
              <p>{item.texto}</p>
              <a
                href="https://wa.me/5584991343413"
                target="_blank"
                rel="noreferrer"
              >
               
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Artigos;
