import React from "react";
import "./Hero.css";
import title from "../../assets/imgs/maintitle.svg";
import { Helmet } from "react-helmet";

function Hero() {
  return (
    <div className="hero-wrapper">
      <Helmet>
        <title>
          Fernandes e Freitas Advogados
        </title>
        <meta
          name="description"
          content="Na Fernandes e Freitas Advocacia e Consultoria, nossa missão é fornecer soluções jurídicas de excelência, personalizadas e eficientes, que atendam às necessidades específicas de nossos clientes."
        />
        <meta
          name="keywords"
          content="advocacia, consultoria jurídica, soluções jurídicas, advogados qualificados, justiça, ética, transparência"
        />
        <link rel="canonical" href="https://ffadvocaciaeconsultoria.com.br/" />
      </Helmet>

      <div className="image-overlay"></div>
      <div className="hero-container hero-section">
        <div className="hero-content text-container">
          <img src={title} alt="" />
          <p>
            Bem-vindo à Fernandes & Freitas Advocacia e Consultoria, onde
            transformamos desafios legais em soluções estratégicas. Combinando
            expertise jurídica com uma abordagem personalizada, estamos aqui
            para proteger seus interesses e alcançar resultados excepcionais.
            Confie na nossa equipe dedicada e experiente para guiá-lo pelo
            labirinto legal, oferecendo respostas claras e estratégias eficazes
            para cada situação. Seu sucesso é nossa prioridade – juntos, podemos
            vencer qualquer batalha legal. Conecte-se conosco e experimente uma
            advocacia que faz a diferença!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Hero;
