// Header.js
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuBars from "../../assets/imgs/menu-bars.svg";
import Menu from "./Menu";
import "./Header.css";
import Logo from "../../assets/imgs/ff.svg";


const Header = ({ scrollToComponent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = async (refName) => {
    const main = process.env.REACT_APP_MAIN;


    const currentPath = location.pathname;

    if (currentPath !== main && currentPath.startsWith(main)) {

      await navigate(main);
    }

    scrollToComponent(refName);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  

  const main = process.env.REACT_APP_MAIN;

  return (
    <div className="header-wrapper">
      <div className="header-container">
        <div className="logo">
          <button
            onClick={() => handleClick("HeroRef")}
            alt="home"
          >
            <img className="logo" src={Logo} alt="logo"></img>
    
          </button>
        </div>

        <nav>
        
          <button onClick={() => handleClick("ServicosRef")}>Especialidades</button>
          <button onClick={() => handleClick("SobreRef")}>Nossos Valores</button>
          <button onClick={() => handleClick("ContatoRef")}>Contato</button>

          <Link onClick={scrollToTop} to={main + 'artigos'}>Ações</Link>

         <a className="chamada" href="https://ffadvocaciaeconsultoria.com.br/kitsparaadvogados/" target="_blank" rel="noreferrer">Kits Para Advogados</a>
         
        </nav>

        <button className="menu-toggle" onClick={toggleSidebar}>
          <img src={MenuBars} alt="Menu Bars" />
        </button>
      </div>
      <Menu
        toggleSidebar={toggleSidebar}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        scrollToComponent={scrollToComponent}
      />
    </div>
  );
};

export default Header;
