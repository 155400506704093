import React from 'react';
import './Footer.css';

const Footer = ({ scrollToComponent }) => {

  const mensagemsite = "Olá! Gostei do desenvolvimento do seu site, gostaria de saber mais.";
  const main = '/';

  return (
    <div className='footer__container'>
      <div className='footer'>
        <div className='footer__child'>
          <div>
            <h3>Sobre</h3>
            <p>
            Conheça nossos serviços especializados em diversas áreas do Direito: Civil, Trabalhista, Empresarial, Tributário, Penal e Administrativo. Conte com nossa expertise para garantir seus direitos e encontrar soluções jurídicas eficazes. Entre em contato e agende sua consulta hoje mesmo!
            </p>
          </div>

    
        </div>


        <div className='footer__child footer__menu'>
          <h3>MENU</h3>

          <button className="main-btn" onClick={() => {
            scrollToComponent("HeroRef");
          }}>Início</button >

          <button className="main-btn"
            onClick={() => {
              scrollToComponent("ServicosRef");
            }}
          >
            Especialidades
          </button>

          <button className="main-btn"
            onClick={() => {
              scrollToComponent("SobreRef");
            }}
          >
            Sobre
          </button>

          <button className="main-btn"
            onClick={() => {
              scrollToComponent("FaqRef");
            }}
          >
            Perguntas Frequentes
          </button>
          <button className="main-btn" onClick={() => {
            scrollToComponent("ContatoRef");
          }}>Contato</button >
        </div>
      </div>

      <div className='copy__container'>
        <div className='copy'>
          {' '}
          <p>
          <a className="main-btn2" href={`https://wa.me/5584996060201?text=${encodeURIComponent(mensagemsite)}`}
            target="_blank"
            rel="noreferrer">Desenvolvido por <span className="testefooter" style={{ color: "#825D50" }}>Luana Salmito</span> </a>
          
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

