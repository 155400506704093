import React from "react";
import "./Contato.css";
import tati from "../../assets/imgs/tati.svg";
import livia from "../../assets/imgs/livia.svg";
import ctt from "../../assets/imgs/contatos.png";
import queen from "../../assets/imgs/queen.svg";

function Contato() {
  const mensagemwpp = "Olá, preciso de ajuda jurídica";
  return (
    <div className="wrapper">
      <div className="contato-container">
        <div className="titulos">
          <img className="img-ctt" src={ctt} alt="" />
 
        </div>
        <div className="imgs">
          <div className="img-dra">
            <img src={tati} alt="" />
            <img src={livia} alt="" />
          </div>
        </div>
        <div className="contato-content">
          <a
            href={`https://api.whatsapp.com/send?phone=5584991321514&text=${encodeURIComponent(
              mensagemwpp
            )}`}
          >
            <p><i class="uil uil-whatsapp whats"></i>     </p>
          </a>
          <a
            href={`https://api.whatsapp.com/send?phone=5584981691100&text=${encodeURIComponent(
              mensagemwpp
            )}`}
          >
            <p> <i class="uil uil-whatsapp whats"></i></p>
          </a>
        </div>
        <div className="textos-content">
        <span>
            <a className="a-marrom" href="mailto:ffadvocaciaconsultiva@gmail.com">
              <i className="uil uil-envelope"></i>    
              ffadvocaciaconsultiva@gmail.com
            </a>
          </span>
          <span>
            <a className="a-marrom"  href="https://instagram.com/ffadvocaciaeconsultiva" target="_blank" rel="noopener noreferrer">
              <i className="uil uil-instagram"></i>
              ffadvocaciaconsultiva
            </a>
          </span>
          <p>
          Nosso escritório, situado em Porto Velho (Rondônia) e Natal (Rio Grande do Norte), oferece atendimento tanto presencial quanto remoto. Com um compromisso inabalável com a excelência, estamos preparados para atender às suas necessidades, independentemente da sua localização geográfica. Entre em contato conosco para agendar uma consulta.
          </p>
          <p>
          Nossa equipe é formada por advogados especializados e com vasta experiência em diversas áreas do direito. Cada membro da F&F Advocacia e Consultoria está dedicado à busca pela justiça e à defesa intransigente dos direitos de nossos clientes.

          </p>
        </div>
      </div>
    </div>
  );
}

export default Contato;
