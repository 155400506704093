import React, { useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useNavigate, Link, useLocation } from "react-router-dom"; // Importe Link
import "./Menu.css";
import Close from "../../assets/imgs/close.svg";

const Menu = ({ toggleSidebar, isOpen, setIsOpen, scrollToComponent }) => {
  const navigate = useNavigate(); // Obtenha a função de navegação
  const location = useLocation();
  const sidebarRef = useRef(null);

  const scrollAction = async (address) => {
    const main = process.env.REACT_APP_MAIN;

    const currentPath = location.pathname;

    if (currentPath !== main && currentPath.startsWith(main)) {
      await navigate(main);
    }

    scrollToComponent(address);
    toggleSidebar();
  };

  const scrollToTop = () => {
    toggleSidebar();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const closeSidebar = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeSidebar);
    return () => {
      document.removeEventListener("mousedown", closeSidebar);
    };
  });

  return (
    <>
      <CSSTransition in={isOpen} timeout={500} classNames="fade" unmountOnExit>
        <div>
          <div className="sidebar" ref={sidebarRef}>
            <button
              className="menu-toggle close-menu-btn"
              onClick={toggleSidebar}
            >
              <img src={Close} alt="Close" />
            </button>

            <div className="menu-btns">
              <button
                onClick={() => {
                  scrollAction("HeroRef");
                  navigate("/#HeroRef");
                }}
                alt="home"
              >
                Início
              </button>
              <button
                onClick={() => {
                  scrollAction("ServicosRef");
                  navigate("/#ServicosRef");
                }}
                alt="home"
              >
                Áreas de atuação
              </button>
              <button
                onClick={() => {
                  scrollAction("SobreRef");
                  navigate("/#SobreRef");
                }}
                alt="home"
              >
                Nossos Valores
              </button>
              <button
                onClick={() => {
                  scrollAction("FaqRef");
                  navigate("/#FaqRef");
                }}
                alt="home"
              >
                Perguntas Frequentes
              </button>
              <button
                onClick={() => {
                  scrollAction("ContatoRef");
                  navigate("/#ContatoRef");
                }}
                alt="home"
              >
                Contato
              </button>
              <Link to="/artigos" onClick={scrollToTop}>
                Ações
              </Link>{" "}
              {/* Adicione o link para Artigos */}

              <a className="chamada" href="https://ffadvocaciaeconsultoria.com.br/kitsparaadvogados/" target="_blank" rel="noreferrer">Kits Para Advogados</a>
            </div>

          </div>
          <div className="curtain"></div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Menu;